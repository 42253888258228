var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-row',{staticStyle:{"margin-top":"100px"}},[_c('b-col',{staticClass:"card d-flex align-items-center px-2 p-5 mx-auto col-md-8 col-sm-8 col-10",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 ",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Change Password ")]),_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.changePass.apply(null, arguments)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"old-password"}},[_vm._v("Old Password")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",class:{
                  'is-invalid border-danger': _vm.form.errors.has('old_password'),
                },attrs:{"id":"old-password","type":_vm.oldPasswordVisible ? 'text' : 'password',"name":"old-password","placeholder":"············"},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.oldPasswordVisible ? 'EyeOffIcon' : 'EyeIcon'},on:{"click":function($event){return _vm.togglePasswordVisibility('old_password')}}})],1)],1),_c('has-error',{attrs:{"form":_vm.form,"field":"old_password"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"new-password"}},[_vm._v("New Password")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",class:{
                  'is-invalid border-danger': _vm.form.errors.has('new_password'),
                },attrs:{"id":"new-password","type":_vm.newPasswordVisible ? 'text' : 'password',"name":"new-password","placeholder":"············"},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.newPasswordVisible ? 'EyeOffIcon' : 'EyeIcon'},on:{"click":function($event){return _vm.togglePasswordVisibility('new_password')}}})],1)],1),_c('has-error',{attrs:{"form":_vm.form,"field":"new_password"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirm Password")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",class:{
                  'is-invalid border-danger':
                    _vm.form.errors.has('confirm_password'),
                },attrs:{"id":"confirm-password","type":_vm.confirmPasswordVisible ? 'text' : 'password',"name":"confirm-password","placeholder":"············"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.confirmPasswordVisible ? 'EyeOffIcon' : 'EyeIcon'},on:{"click":function($event){return _vm.togglePasswordVisibility('confirm_password')}}})],1)],1),_c('has-error',{attrs:{"form":_vm.form,"field":"confirm_password"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"danger","block":"","disabled":_vm.form.busy}},[_vm._v(" Change Password ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }