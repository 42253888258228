<template>
  <div class="">
    <b-row style="margin-top: 100px">
      <b-col
        lg="4"
        class="card d-flex align-items-center px-2 p-5 mx-auto col-md-8 col-sm-8 col-10"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 ">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Change Password
          </b-card-title>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="changePass"
            @keydown="form.onKeydown($event)"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="old-password">Old Password</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="old-password"
                  v-model="form.old_password"
                  class="form-control-merge"
                  :type="oldPasswordVisible ? 'text' : 'password'"
                  name="old-password"
                  placeholder="············"
                  :class="{
                    'is-invalid border-danger': form.errors.has('old_password'),
                  }"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="oldPasswordVisible ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePasswordVisibility('old_password')"
                  />
                </b-input-group-append>
              </b-input-group>
              <has-error :form="form" field="old_password"></has-error>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="new-password">New Password</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="new-password"
                  v-model="form.new_password"
                  class="form-control-merge"
                  :type="newPasswordVisible ? 'text' : 'password'"
                  name="new-password"
                  placeholder="············"
                  :class="{
                    'is-invalid border-danger': form.errors.has('new_password'),
                  }"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="newPasswordVisible ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePasswordVisibility('new_password')"
                  />
                </b-input-group-append>
              </b-input-group>
              <has-error :form="form" field="new_password"></has-error>
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="confirm-password">Confirm Password</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="confirm-password"
                  v-model="form.confirm_password"
                  class="form-control-merge"
                  :type="confirmPasswordVisible ? 'text' : 'password'"
                  name="confirm-password"
                  placeholder="············"
                  :class="{
                    'is-invalid border-danger':
                      form.errors.has('confirm_password'),
                  }"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmPasswordVisible ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePasswordVisibility('confirm_password')"
                  />
                </b-input-group-append>
              </b-input-group>
              <has-error :form="form" field="confirm_password"></has-error>
            </b-form-group>
            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="danger"
              block
              :disabled="form.busy"
            >
              Change Password
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";

import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    HasError,
    AlertError,
  },
  data() {
    return {
      form: new Form({
        old_password: null,
        new_password: null,
        confirm_password: null,
      }),
      oldPasswordVisible: false,
      newPasswordVisible: false,
      confirmPasswordVisible: false,
    };
  },

  methods: {
    changePass() {
      this.form
        .post("/app/password-change", this.form)
        .then((res) => {
          if (res.data) {
            this.s(res.data.message);
            this.$router.push({
              name: "dashboard",
            });
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    togglePasswordVisibility(field) {
      if (field == "old_password") {
        this.oldPasswordVisible = !this.oldPasswordVisible;
      } else if (field == "new_password") {
        this.newPasswordVisible = !this.newPasswordVisible;
      } else if (field == "confirm_password") {
        this.confirmPasswordVisible = !this.confirmPasswordVisible;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
